

<template>
  <transition name="fade">
  <div class="preferences">
   <main-nav />
    <main-titlebar myclass="yellowBar" showback="true" icon="fas fa-list-check"/>


      <div class="grid-box-vscale">
        <p>Stel hier uw voorkeuren in.</p>
      </div>
      <div v-for="p in profile" :key="p.id" >




        <Form @submit="savePreferences" >
        <Field name="id"  v-model="p.id"  type="hidden"/>

        <div class="form-group">
          <div class="col-25 textAlignCenter">
            <Field id="show_notifications" name="show_notifications" type="checkbox" class="form-input" v-model="p.show_notifications" value="1" unchecked-value="0" />
          </div>
          <div class="col-75 textAlignLeft ">
            <div class="form-label textAlignLeft">
              <label for="notifications">Notificaties weergeven</label>
            </div> 
          </div>
        </div>
 
  

        <div class="trip-view-row">
          <div class="trip-view-col">
            <button class="default-button " >
              <span
                
                class="spinner-border spinner-border-sm"
              ></span>
              <span><i class="fa-solid fa-save"></i> Opslaan</span>
            </button>
          </div>
        </div>
        </Form>


      </div>


  </div>
  </transition>
</template>
<script>
import UserService from "../../services/user-service";
import { Form, Field } from "vee-validate";
export default {
  name:'view-preferences',
  data() {
    var status = 'loading'
    var profile;
    var notifications;


    return { profile, notifications, status}
  },
  mounted() {
    var targ = this;
    UserService.getMyProfile().then(
      (response) => {
        targ.status = response.status;
        targ.profile = response.data;
      },
      (error) => {
          error.toString();
      }
    );


  },
  methods: {
    savePreferences(user) {
     this.loading = true;
      console.log("savePreferences>> contact:" + JSON.stringify(user));

      UserService.saveMyProfile(user).then(
        (response) => {
           console.log("savePreferences>> response:" + JSON.stringify(response));
           if(response.status == "failed") {
            alert("savePreferences>> Error: "+ response.message);
            // this.$router.go();

           } else {
             this.content = response.data;
            this.$router.push("/my/preferences");
           }
        },
        (error) => {
          console.log("savePreferences>> error:" + JSON.stringify(error));
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.$router.push("/my/preferences");
        }
      );
    },
  },
  components: {
    Form,
    Field,
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,

  }
}
</script>
